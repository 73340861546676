import React from "react";
import {useContext, useEffect, useState} from "react";
import ManagersTable from "../components/ManagerTable";
import AddManager from "../components/AddManager";
import $api from "../http";

const Table = () => {
    const [managers, setManagers] = useState([]);

    const getManagers = async () => {
        try{
            const response = await $api.get('/managers/get');
            setManagers(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const addManager = async (name) => {
        try{
            await $api.post('/managers/add', {name});
            getManagers();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const deleteManager = async (manager) => {
        try {
            await $api.delete(`/managers/delete/${manager.id}`);
            getManagers();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const editManager = async(managerId, newValue) => {
        const findManager = managers.filter((manager) => manager.id === managerId);
        if(findManager[0].value !== newValue){
            try{
                await $api.patch(`/managers/editValue/${managerId}`, {newValue});
                getManagers();
            }catch(error){
                alert(error.response.data.message);
            }
        }
    }

    useEffect(() => {
        getManagers();
    }, []);

    return(
        <>
            <div className='flex mx-auto justify-evenly mb-20 mt-20'>
                <div className='flex flex-col-reverse items-center w-full'>
                    <ManagersTable managers={managers} deleteManager={deleteManager} editManager={editManager} />
                    <AddManager addManager={addManager} />
                </div>
            </div>
        </>
    )
}

export default Table;