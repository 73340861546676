import React, { useState } from "react";

const AddManager = (props) => {

    const [name, setName] = useState();

    const addManager = () => {
        if(name){
            props.addManager(name);
            setName('');
        }else{
            alert('Заполните пустые поля');
        }
    }

    return(
        <>
            <div className='bg-gray-200 px-4 py-2 w-2/5 rounded h-fit'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Добавить менеджера</h1>
                <div className='grid grid-cols-2 gap-y-4 justify-items-center mt-4 custom-table'>
                    <input className='w-5/6 h-fit my-auto' placeholder='NAME' value={name} onChange={(e) => setName(e.target.value)}/>
                    <button className='uppercase font-bold px-4 py-2 border border-black rounded hover:bg-black hover:text-white' onClick={addManager}>Добавить</button>
                </div>
            </div>
        </>
    )
}

export default AddManager;