import React, { useState } from "react";
import trash from "../images/trash.svg";
import pencil from "../images/pencil.svg";
import confirm from "../images/confirm.svg";
import cancel from "../images/cancel.svg";

const ManagersTable = (props) => {

    const [editMode, setEditMode] = useState(0);
    const [newValue, setNewValue] = useState(0);

    const editManagerName = () => {
        if(newValue){
            props.editManager(editMode, newValue);
            setEditMode(0);
            setNewValue(0);
        }else{
            alert('Измените имя или отмените редактирование');
        }
    }

    return(
        <>
            <div className='bg-gray-200 px-4 py-2 w-2/5 rounded h-fit mt-8'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Мои менеджеры</h1>
                <table className='my-table'>
                    <tr className='border-b-2 border-gray-400'>
                        <th>ID</th>
                        <th>ИМЯ</th>
                        <th>РЕЗУЛЬТАТ</th>
                        <th></th>
                    </tr>
                    {props.managers?.map((manager, index) => (
                        <>
                            <tr key={index}
                                className={`text-center border-b-2 border-gray-300 ${editMode !== manager.id ? '' : 'hidden'}`}>
                                <td>{manager.id}</td>
                                <td>{manager.name}</td>
                                <td>{manager.value}</td>
                                <td className='flex justify-evenly'>
                                    <img src={pencil} width={30} onClick={() => {
                                        setEditMode(manager.id);
                                        setNewValue(manager.value);
                                    }} className='cursor-pointer border border-black rounded p-1'/>
                                    <img src={trash} width={30} onClick={() => props.deleteManager(manager)}
                                         className='cursor-pointer border border-black rounded p-1'/>
                                </td>
                            </tr>
                            <tr key={index}
                                className={`text-center border-b-2 border-gray-300 custom-table ${editMode === manager.id ? '' : 'hidden'}`}>
                                <td>{manager.id}</td>
                                <td>{manager.name}</td>
                                <td><input value={newValue} onChange={(e) => setNewValue(e.target.value)}
                                           className='text-center w-4/5'/></td>
                                <td className='flex justify-evenly'>
                                    <img src={confirm} width={30} onClick={editManagerName}
                                         className='cursor-pointer border border-black rounded'/>
                                    <img src={cancel} width={30} onClick={() => setEditMode(0)}
                                         className='cursor-pointer border border-black rounded'/>
                                </td>
                            </tr>
                        </>
                    ))}
                </table>
            </div>
        </>
    )
}

export default ManagersTable;