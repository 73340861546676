import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Chart from "./pages/Chart";
import Table from "./pages/Table";


const App = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Chart />}/>
                <Route path="/panel" element={<Table />}/>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
