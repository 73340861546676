import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const BarChart = ({ data, backgroundImage }) => {
    const svgRef = useRef();

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove(); // Очистка SVG перед каждым рендером

        const maxItemsPerColumn = 9;
        const barHeight = 50;
        const barSpacing = 10;
        const leftPadding = 200; // Дополнительное место для текста слева
        const chartPadding = 50;
        const columnSpacing = 250; // Увеличенный отступ между колонками

        // Определение количества колонок
        const columnCount = Math.ceil(data.length / maxItemsPerColumn);
        const columnWidth = 600; // Ширина одной колонки
        const height = (barHeight + barSpacing) * maxItemsPerColumn + chartPadding * 2 + 50;
        const width = columnWidth * columnCount + columnSpacing * (columnCount - 1); // Учитываем отступы между колонками

        // Устанавливаем viewBox и делаем SVG адаптивным
        svg.attr("viewBox", `0 0 ${width + leftPadding} ${height}`)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .attr("width", "100%")
            .attr("height", "100vh");


        const barColor = "#058240"; // Единый цвет для всех баров

        // Построение баров с учётом переноса
        data.forEach((d, i) => {
            const column = Math.floor(i / maxItemsPerColumn);
            const row = i % maxItemsPerColumn;

            const barGroup = svg.append("g")
                .attr("transform", `translate(${column * (columnWidth + columnSpacing) + chartPadding + leftPadding}, ${(row * (barHeight + barSpacing)) + chartPadding})`);

            barGroup.append("rect")
                .attr("width", columnWidth - chartPadding * 2) // Устанавливаем максимальную ширину для всех баров
                .attr("height", barHeight)
                .attr("fill", barColor);

            // Текст в центре бара
            barGroup.append("text")
                .attr("x", (columnWidth - chartPadding * 2) / 2)
                .attr("y", barHeight / 2)
                .attr("dy", ".35em")
                .attr("text-anchor", "middle")
                .attr("fill", "white")
                .attr("font-size", 24)
                .attr("font-weight", 900)
                .style("font-family", "cursive")
                .style("stroke", "black") // Цвет обводки
                .style("stroke-width", 6) // Толщина обводки
                .style("paint-order", "stroke fill") // Приоритет обводки
                .style("text-shadow", "4px 4px 8px rgba(0, 0, 0, 0.7)") // Добавляем тень
                .text(() => {
                    const value = d.value; // Значение
                    return new Intl.NumberFormat('de-DE').format(value); // Форматирует в стиле "1.000.000"
                });

            // Текст слева от бара с отступом между строками
                barGroup.append("text")
                    .attr("x", -leftPadding + 10) // Смещаем текст влево
                    .attr("y", barHeight / 2 )//+ index * 22 - (words.length - 1) * 10) // Отступ между строками
                    .attr("dy", ".35em")
                    .attr("text-anchor", "start")
                    .attr("fill", "white") // Основной цвет текста
                    .attr("font-size", 24)
                    .attr("font-weight", 900)
                    .style("font-family", "cursive")
                    .style("text-shadow", "4px 4px 8px rgba(0, 0, 0, 0.7)") // Добавляем тень
                    .style("stroke", "black") // Цвет обводки
                    .style("stroke-width", 6) // Толщина обводки
                    .style("paint-order", "stroke fill") // Приоритет обводки
                    .text((d.name).toUpperCase());

            svg.append("text")
                .attr("x", width / 2 + leftPadding / 2) // Центр текста
                .attr("y", height) // Ниже диаграммы
                .attr("text-anchor", "middle")
                .attr("fill", "white") // Основной цвет текста
                .attr("font-size", 36)
                .attr("font-weight", 900)
                .style("font-family", "cursive")
                .style("text-shadow", "2px 2px 4px rgba(0, 0, 0, 0.7)") // Добавляем тень
                .style("stroke", "black") // Цвет обводки
                .style("stroke-width", 6) // Толщина обводки
                .style("paint-order", "stroke fill") // Приоритет обводки
                .text(`ТОТАЛ: ${Intl.NumberFormat('de-DE').format(data?.reduce((sum, item) => sum + item.value, 0))}`);
        });
    }, [data, backgroundImage]);

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <svg ref={svgRef} style={{ width: '100%', height: '100vh' }}></svg>
        </div>
    );
};

export default BarChart;