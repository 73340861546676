import BarChart from "../components/Bar";
import {useEffect, useState} from "react";
import $api from "../http";

const Chart = () => {

    const [data, setData] = useState([]);

    const getManagers = async () => {
        try{
            const response = await $api.get('/managers/get');
            setData(response.data.sort((a, b) => b.value - a.value));

        }catch(error){
            alert(error.response.data.message);
        }
    }

    useEffect(() => {
        getManagers();
    }, []);

    useEffect(() => {
        getManagers();

        const intervalId = setInterval(() => {
            getManagers();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{backgroundImage: `url(/images/bg.jpg)`, backgroundSize: 'cover', width: '100%', height: '100vh'}}>
            <BarChart data={data}/>
        </div>
    );
}

export default Chart;
